body {
  overflow: overlay;
}
*::-webkit-scrollbar-track {
  display: none;
}

*::-webkit-scrollbar-track-piece:start,
*::-webkit-scrollbar-track-piece:end,
*::-webkit-scrollbar * {
  background: transparent;
}

*::-webkit-scrollbar {
  width: 10px;
  background-color: transparent;
  z-index: 1000000;
}

*::-webkit-scrollbar-thumb {
  border-radius: 6px;
  transition: 300ms;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(153, 151, 151);
  z-index: 10;
  visibility: hidden;
}

*::-webkit-scrollbar-thumb:hover {
  visibility: visible;
}

.react-pdf__Page__annotations {
  display: none;
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
}

.small-input input {
  padding: 4px 8px;
}
